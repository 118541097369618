// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-biography-js": () => import("./../../../src/pages/biography.js" /* webpackChunkName: "component---src-pages-biography-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-discography-js": () => import("./../../../src/pages/discography.js" /* webpackChunkName: "component---src-pages-discography-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lesson-js": () => import("./../../../src/pages/lesson.js" /* webpackChunkName: "component---src-pages-lesson-js" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-topics-js": () => import("./../../../src/pages/topics.js" /* webpackChunkName: "component---src-pages-topics-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

